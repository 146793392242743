<template>
    <div class="html-default">
        <table border="1" class="mytable mytable-head">
            <thead>
                <tr>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Judul</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Grup Akun</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Saldo Awal</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Saldo</label></td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td class="px-2"><label>{{item['head_name']}}</label></td>
                    <td class="px-2"><label>{{item['group_name']}}</label></td>
                    <td class="px-2"><label>{{item['initial_balance']}}</label></td>
                    <td class="px-2"><label>{{item['balance']}}</label></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import trialBalanceServices from '../../Areas/Accounting/TrialBalance/Script/TrialBalanceServices';

export default {
    name: 'TrialBalanceHtml',
    data(){
        return{
            DateFrom: this.$route.params.dateFrom,
            DateTo: this.$route.params.dateTo,
            Setting: this.$route.params.setting,
            items: [],
        }
    },
    async mounted () {
        var startDate = this.DateFrom,
            endDate = this.DateTo,
            setting = this.Setting;

        var variables = {
                startDate,
                endDate,
                setting
            };
            
        var data = await trialBalanceServices.getBalanceQuery(variables);
        var gridData = trialBalanceServices.balanceItemPDF(data);
        this.items = gridData;
    },
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>